import { gql } from '@apollo/client'

const serializeData = (data) => btoa(JSON.stringify(data))
const deserializeData = (data) => JSON.parse(atob(data))

export const GET_CURRENT_USER = gql`
  query GetAppErrors {
    currentUser @client
  }
`

const tracking = {
  userIdentified: false,
  init(apolloClient) {
    this.apolloClient = apolloClient
  },
  async identify() {
    if (!window || !analytics) {
      return
    }

    /* Query user data from GQL before calling the identify method */
    const res = await this.apolloClient.query({ query: GET_CURRENT_USER })
    const user = res.data?.currentUser
    if (!user) return

    const { id, uiUserType: userType, groups, flags } = user

    analytics.identify(id, {
      userType,
      groups,
      flags: flags.map(({ name, active }) => ({ name, active })), // remove Apollo __typename field
    })

    this.userIdentified = true
  },
  async trackEvent({ id, ...eventProperties }) {
    if (!window || !analytics) {
      return
    }

    try {
      analytics.track(id, eventProperties)
    } catch (err) {
      if (!(err instanceof TypeError)) {
        throw err
      }
    }
  },
  async attachGlobalClickListener() {
    if (!window || !analytics) {
      return
    }

    const trackClickEvent = async ({ target }) => {
      if (!target || target.tagName.toLowerCase() === 'body') {
        return null
      }

      if (!target.dataset?.trackingInfo) {
        /*
          Traverse the entire tree until we find an element with data-tracking-info or the <body> tag
          useful when we click an untracked element that is a child of an element that we wish to track.
          This might be an overkill, but since it's async I don't see a glaring performance impact atm
        */
        return trackClickEvent({ target: target.parentNode })
      }

      const { id, ...eventProperties } = deserializeData(target.dataset.trackingInfo)

      try {
        analytics.track(id, eventProperties)
      } catch (err) {
        if (!(err instanceof TypeError)) {
          throw err
        }
      }
      return null
    }

    if (document) {
      document.body.addEventListener('click', trackClickEvent)
    }
  },
  async page(data) {
    if (!window || !analytics) {
      return
    }

    try {
      analytics.page(data)
    } catch (err) {
      if (!(err instanceof TypeError)) {
        throw err
      }
    }
  },
}

export default tracking

export { serializeData }
