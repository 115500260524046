import { UserProvider } from '@auth0/nextjs-auth0'
import '@fontsource/inter'
import '@fontsource/lato'
import '@fontsource/lato/100.css'
import '@fontsource/lato/300.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import '@fontsource/merriweather'
import MarkdownIt from 'markdown-it'
import App from 'next/app'
import Head from 'next/head'
import Router, { withRouter } from 'next/router'
import React from 'react'
import 'react-dates/lib/css/_datepicker.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { install } from 'resize-observer'
import sanitizeHtml from 'sanitize-html'

import { StylesProvider } from '@material-ui/core/styles'

import sentry from '@lib/sentry'
import tracking from '@lib/tracking'

// Track route changes with segment
Router.events.on('routeChangeComplete', (url) => tracking.page({ url }))

if (typeof window !== 'undefined') {
  install()
}

const { Sentry, captureException } = sentry()
class MyApp extends App {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorEventId: undefined,
    }
  }

  // static async getInitialProps({ Component, ctx }) {
  //   try {
  //     let pageProps = {}
  //
  //     if (Component.getInitialProps) {
  //       pageProps = await Component.getInitialProps(ctx)
  //     }
  //
  //     return { pageProps }
  //   } catch (error) {
  //     // Capture errors that happen during a page's getInitialProps.
  //     // This will work on both client and server sides.
  //     const errorEventId = captureException(error, ctx)
  //     return {
  //       hasError: true,
  //       errorEventId,
  //     }
  //   }
  // }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    }
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const self = this
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })

      const errorEventId = captureException(error, { errorInfo })
      self.setState({ errorEventId })
    })

    super.componentDidCatch(error, errorInfo)
  }

  componentDidMount() {
    window['__react-beautiful-dnd-disable-dev-warnings'] = true
    // window.$mdParser = new MarkdownIt()
    const markdownIt = new MarkdownIt()

    // https://github.com/markdown-it/markdown-it/issues/140
    // https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
    // Remember old renderer, if overridden, or proxy to default renderer
    const defaultRender =
      markdownIt.renderer.rules.link_open ||
      ((tokens, idx, options, env, self) => self.renderToken(tokens, idx, options))

    markdownIt.renderer.rules.link_open = (tokens, idx, options, env, self) => {
      // If you are sure other plugins can't add `target` - drop check below
      const aIndex = tokens[idx].attrIndex('target')

      if (aIndex < 0) {
        tokens[idx].attrPush(['target', '_blank']) // add new attribute
      } else {
        // eslint-disable-next-line no-param-reassign
        tokens[idx].attrs[aIndex][1] = '_blank' // replace value of existing attr
      }

      // pass token to default renderer.
      return defaultRender(tokens, idx, options, env, self)
    }

    const { ...defaultSanitizeOptions } = sanitizeHtml.defaults
    defaultSanitizeOptions.allowedTags = defaultSanitizeOptions.allowedTags.concat(['h1', 'h2'])
    // uncomment this when we will have a clear function on lcicking mentions
    // defaultSanitizeOptions.allowedSchemes = defaultSanitizeOptions.allowedSchemes.concat(['mention'])

    window.$mdSanitizedParse = (md, options = {}) => ({
      __html: sanitizeHtml(markdownIt.render(md), { ...defaultSanitizeOptions, ...options }),
    })

    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }

    tracking.attachGlobalClickListener()
  }

  render() {
    const { Component, pageProps } = this.props

    if (this.state.hasError) {
      return (
        <section>
          <h1>There was an error!</h1>
          <p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => Sentry.showReportDialog({ eventId: this.state.errorEventId })}
            >
              <span role="img" aria-label="report">
                📣
              </span>
              Report this error
            </a>
          </p>
          <p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                window.location.reload(true)
              }}
            >
              Or, try reloading the page
            </a>
          </p>
        </section>
      )
    }

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <UserProvider>
          <StylesProvider injectFirst>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component {...pageProps} />
          </StylesProvider>
        </UserProvider>
      </>
    )
  }
}

export default withRouter(MyApp)
